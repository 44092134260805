import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Privacy",
  "route": "/privacy"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy`}</h1>
    <p>{`Smartlike doesn't collect private user data. There's no signup or registration. Users generate account keys locally and store them in a password manager. Login and donation signing operations are performed in the browser. All transactions are stored on `}<a parentName="p" {...{
        "href": "/audit"
      }}>{`the public ledger`}</a>{`. Sensitive parts like direct messages and device synchronization are end-to-end encrypted by users.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      